export const ElectricityIcon = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.967 3 4.75 14H12l-.967 7 8.217-11H12l.967-7Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const ShoppingBasketIcon = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M18.064 7H5.936a1 1 0 0 0-.998.933l-.796 11.934A2 2 0 0 0 6.138 22h11.724a2 2 0 0 0 1.996-2.133l-.796-11.934A1 1 0 0 0 18.064 7Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.001 9V5v0a3 3 0 0 0-3-3H12a3 3 0 0 0-3 3v4M14.187 17.441a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5M9.815 13.066a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5M9.57 17.94l4.87-4.87"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const MobilePhone = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6 19H4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1ZM13 19h-2a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1ZM20 19h-2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const House = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 20v-5a1 1 0 0 1 1-1h2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="m17.774 15.158.226.23.226-.23a2.187 2.187 0 0 1 3.127 0v0a2.27 2.27 0 0 1 0 3.174l-2.116 2.148a1.731 1.731 0 0 1-2.474 0l-2.116-2.148a2.27 2.27 0 0 1 0-3.175v0a2.187 2.187 0 0 1 3.127 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m3 10.58 7.698-6.598a2 2 0 0 1 2.604 0L21 10.58M4.285 9.479V20M12 20H3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.624 5.972V3.75a.75.75 0 0 1 .75-.75h2.59a.75.75 0 0 1 .75.75v5.728"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const CreditCard = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14h2M8 17h1M9 21H5a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v2M14.5 7V6A3.5 3.5 0 0 0 11 2.5v0A3.5 3.5 0 0 0 7.5 6v1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M12 16v3a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-5a2 2 0 0 0-2 2Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M21 17h-9"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const TV = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      height="15"
      rx="3"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="20"
      x="2"
      y="6"
    />
    <path
      d="M16 21V6M17 2l-5 4M7 2l5 4M19 14.995l.005.005-.005.005-.005-.005.005-.005M19 12.495l.005.005-.005.005-.005-.005.005-.005M19 9.995l.005.005-.005.005-.005-.005.005-.005"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const PiggyBank = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 25 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.428 4.025a3.5 3.5 0 1 1-4.95 4.95 3.5 3.5 0 0 1 4.95-4.95M17.486 11.967a.046.046 0 1 1-.065.066.046.046 0 0 1 .065-.066"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.433 6.859a3.14 3.14 0 0 0 .922-.389A2.937 2.937 0 0 1 17.953 6h1v2.554a5.984 5.984 0 0 1 1.626 2.381h.874a1 1 0 0 1 1 1V15a1 1 0 0 1-1 1h-1.31a6.035 6.035 0 0 1-2.19 2.191V20a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1h-3.5v.868a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2.405a5.976 5.976 0 0 1-2-4.463 6 6 0 0 1 4.098-5.687M2.741 10a1.57 1.57 0 0 0-.788 1.358c0 .871.706 1.577 1.577 1.577h.923M8.953 10h6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const Clapperboard = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 24 24"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4.611 11H19a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H6.611a2 2 0 0 1-2-2v-8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.154 9.444v-3.67M14.296 7.969v-3.94"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M19.087 7.14 4.61 11l-.55-2.204A2 2 0 0 1 5.484 6.38l12.543-3.345a1 1 0 0 1 1.228.724l.543 2.173a1 1 0 0 1-.712 1.209Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
