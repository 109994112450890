import { classnames } from "@vgno/utils";

import {
  Clapperboard,
  CreditCard,
  ElectricityIcon,
  House,
  MobilePhone,
  PiggyBank,
  ShoppingBasketIcon,
  TV,
} from "./Icons";

import styles from "./VGLabBox.module.css";

const icons = {
  clapperboard: <Clapperboard className={styles.vglab_box__icon} />,
  creditCard: <CreditCard className={styles.vglab_box__icon} />,
  electricity: <ElectricityIcon className={styles.vglab_box__icon} />,
  house: <House className={styles.vglab_box__icon} />,
  mobilePhone: <MobilePhone className={styles.vglab_box__icon} />,
  piggyBank: <PiggyBank className={styles.vglab_box__icon} />,
  shoppingBasket: <ShoppingBasketIcon className={styles.vglab_box__icon} />,
  tv: <TV className={styles.vglab_box__icon} />,
};

export type VGLabBoxProps = {
  articleSlug?: string;
  icon?: string;
  link: {
    text: string;
    url: string;
    utm_content: string;
  };
  pink?: boolean;
  text: string;
  standalone?: boolean;
};

const withUtm = (url: string, utm_content: string, article?: string) => {
  const utm = `utm_source=vg&utm_medium=article&utm_content=${utm_content}${article ? `&utm_name=${article}` : ""}`;
  return `${url}${url.includes("?") ? "&" : "?"}${utm}`;
};

const TextSpan = ({ link, text }) => {
  const index = text.indexOf(link.text);

  const beforeLink = text.substring(0, index);
  const afterLink = text.substring(index + link.text.length);

  return (
    <>
      {beforeLink}
      <span className={styles.vglab_box__highlight}>{link.text}</span>
      {afterLink}
    </>
  );
};

export const VGLabBox = (props: VGLabBoxProps) => {
  const { articleSlug, icon, link, pink, text, standalone } = props;

  const utm_content = pink
    ? link.utm_content.replace("hvit", "rosa")
    : link.utm_content;
  return (
    <div
      className={classnames(
        "layout-component",
        "layout-normal",
        "layout-padded",
        styles.vglab_box,
        standalone && styles.standalone,
      )}
    >
      <a
        href={withUtm(link.url, utm_content, articleSlug)}
        data-track-element-type="vglab-box"
        data-track-id={utm_content}
        data-track-name="vglab-box"
        data-track-impression
      >
        <div
          className={classnames(
            styles.vglab_box__content,
            pink ? styles.pink_bg : styles.white_bg,
          )}
        >
          {icon && icons[icon]}
          <p>
            <TextSpan link={link} text={text} />
          </p>
        </div>
      </a>
    </div>
  );
};
